import React, { useState, useEffect, useRef } from "react";
import { PRODUCTS } from "../../products";
import { Product } from "./product";
import { Benefits } from "../../components/benefits";
import "./shop.css";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import character2 from "../../assets/character2.png";
import character4 from "../../assets/character4.png";
import logo from "../../assets/dimesmgmt2.png";
//import welcomeimage from "../../assets/welcomeimage.png"; // Import your logo image
import welcomeimage from "../../assets/welcomeimage.png";
import { CustomerReviews } from "../../components/reviews.jsx";
import agedaccs from "../../assets/agedaccs.png";
// In shop.jsx
import ReviewsCarousel from "../../components/reviews.jsx";


const characters = [character2, character4];
const messages = ["Cookies Included", "Organically Farmed", "Hand-Grown"]; // Text messages for speech bubble

export const Shop = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [productCharacters, setProductCharacters] = useState({});
  const [loadedImages, setLoadedImages] = useState({});
  const [speechBubbleText, setSpeechBubbleText] = useState({}); // Track speech bubble text for each product
  const intervals = useRef({}); // Store intervals for each product

  useEffect(() => {
    const initialCharacters = PRODUCTS.reduce((acc, product) => {
      const randomCharacter =
        characters[Math.floor(Math.random() * characters.length)];
      acc[product.id] = randomCharacter;
      return acc;
    }, {});
    setProductCharacters(initialCharacters);

    // Initialize alternating messages for each product
    const initialText = PRODUCTS.reduce((acc, product) => {
      acc[product.id] = messages[0];
      return acc;
    }, {});
    setSpeechBubbleText(initialText);

    // Cleanup intervals on unmount
    return () => {
      Object.values(intervals.current).forEach(clearInterval);
    };
  }, []);

  const startAlternatingMessages = (productId) => {
    if (intervals.current[productId]) {
      clearInterval(intervals.current[productId]); // Clear any existing interval
    }

    let index = 0;
    intervals.current[productId] = setInterval(() => {
      index = (index + 1) % messages.length;
      setSpeechBubbleText((prevText) => ({
        ...prevText,
        [productId]: messages[index],
      }));
    }, 1500); // Alternate every 2 seconds
  };

  const handleMouseEnter = (productId) => {
    // Reset to the initial message and start alternating
    setSpeechBubbleText((prevText) => ({
      ...prevText,
      [productId]: messages[0],
    }));
    startAlternatingMessages(productId);
  };

  const handleMouseLeave = (productId) => {
    // Stop alternating messages
    if (intervals.current[productId]) {
      clearInterval(intervals.current[productId]);
    }
  };

  const handleImageLoad = (productId) => {
    setLoadedImages((prev) => ({ ...prev, [productId]: true }));
  };

  const handleClick = (productId) => {
    if (productId === 1) {
      navigate("/aged"); // Redirect to /aged for product with id 1
    } else if (productId === 2) {
      navigate("/highkarma"); // Redirect to /highkarma for product with id 2
    }
  };
  
  return (
    <div className="shop">
      
  {/* Welcoming Section */}
  <div className="welcome-section">
    <div className="welcome-image">
      <img src={welcomeimage} alt="Welcome" className="Welcome" />
      <div className="welcome-text shining-text">
  Boost your reddit marketing.
  <div className="subtext">redplug, the top choice for high-quality, aged reddit accounts.</div>
</div>

      
    </div>
  </div>

  {/* Title Section */}
  {PRODUCTS.length > 0 && (
    <div className="title-section">
      <h1 className="explore-title">Explore Our Packages</h1>
    </div>
  )}

  {/* Out of Stock Message */}
  {PRODUCTS.length === 0 ? (
    <div className="out-of-stock shining-text-out">
      <h2>
        High Karma Accounts have been <span className="sold-out-text">sold out</span>.
        Follow us on <a href="https://t.me/redplug" target="_blank" rel="noopener noreferrer" className="telegram-link">Telegram</a> to catch the next drop
      </h2>
    </div>
  ) : (
    <div className="products">
      {PRODUCTS.map((product) => (
        <div
          className="product-container"
          key={product.id}
          onMouseEnter={() => handleMouseEnter(product.id)}
          onMouseLeave={() => handleMouseLeave(product.id)}
          onClick={() => handleClick(product.id)} // Add onClick to navigate
        >
          <Product data={product} />
          <img
            src={productCharacters[product.id]}
            alt=""
            className={`peeking-character ${loadedImages[product.id] ? "loaded" : ""}`}

            onLoad={() => handleImageLoad(product.id)}
          />
          
        </div>
      ))}
    </div>
  )}

  {/* Aged Accounts Section */}



  {/* Benefits & Reviews Section - Side by Side */}
{/* Benefits & Reviews Section - Responsive Layout */}
<div className="benefits-reviews-container">
  {/* Benefits Section */}
  <div className="benefits-wrapper">
    <Benefits />
  </div>

  {/* Reviews Section */}
  <div className="reviews-wrapper">
    
    <h2 className="shining-text-rev">What our customers say...</h2>
    <CustomerReviews />
  </div>
</div>


</div>

  );
  
  
  
  
};