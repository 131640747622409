// src/pages/aged/Aged.jsx
import React from 'react';
import './aged.css'; // Ensure the CSS file is imported
import character from "../../assets/character22.png"; // Import character image

const Aged = () => {
  return (
    <div className="aged">
      <img src={character} alt="Character 2" className="aged-character" />
      <h1>Blank Aged Accounts $3/ea</h1>
      
      {/* Display the character image */}
      <p className="aged-description">
        BLANKS | 1 Month+ Aged | Female Usernames | Includes Cookies | Created Using US Mobile Proxies
      </p>

      {/* Add a subtext for DM to purchase with a clickable link */}
      <p className="aged-dm">
        <a href="https://t.me/dimesmgmt" target="_blank" rel="noopener noreferrer">
          dm @dimesmgmt to purchase
        </a>
      </p>
    </div>
  );
};

export default Aged;
