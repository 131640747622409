import React from "react";
import "./reviews.css"; // Ensure this file contains the shining effect styling

const reviews = [
  {
    id: 1,
    channel: "redplug vouches",
    name: "Forwarded from TODOROV",
    review:
      "Dimes has the best reddit accounts in the game always delivers with high quality karma and speedy results BIG VOUCH, spent 135 for 3 accs",
  },
  {
    id: 2,
    channel: "redplug vouches",
    name: "Forwarded from Majeta St**ic",
    review: "Thank you for the account, delivery + answer was really fast 👍🏻",
  },
  {
    id: 3,
    channel: "redplug vouches",
    name: "Forwarded from Palve Djo****ic",
    review:
      "Just bought 2 accs from Dimes both work totally fine as well as the emails, great customer support as well🤝💪",
  },
  {
    id: 4,
    channel: "redplug vouches",
    name: "Forwarded from Velizar Dim****ov",
    review:
      "I purchased a Reddit account from Dimes, and I was impressed with how smooth and secure the process was. The account was exactly as described, with a solid history and karma. The seller communicated well, answered all my questions, and made the transfer easy. Highly recommend Adam for a trustworthy and hassle-free experience 🔥",
  },
  {
    id: 5,
    channel: "redplug vouches",
    name: "Forwarded from Ni***nEX",
    review:
      "Dimes sent me the Reddit accounts immediately, gave me instructions on the accounts, supplied cookies with the accounts, and did not require a MM to provide the accounts. I sent half up front and he sent the first account. Trustworthy and reliable :)",
  },
  {
    id: 6,
    channel: "redplug vouches",
    name: "Forwarded from Ob****on Empire",
    review: "so far i have bought 3 accounts from Dimes and everything has been perfect :)))",
  },
  {
    id: 7,
    channel: "redplug vouches",
    name: "Forwarded from emi",
    review: "W Accounts provider💪🏼🥷",
  },{
    id: 8,
    channel: "redplug vouches",
    name: "Forwarded from Tod*rov",
    review: "Bro you the plug fr 🤞",
  },
  



  {
    id: 9,
    channel: "redplug vouches",
    name: "Forwarded from Johnny",
    review: "it was a pleasure working with you. The cooperation was smooth and productive ",
  },{
    id: 10,
    channel: "redplug vouches",
    name: "Forwarded from RM",
    review: "Appreciate that G! Real talk you’re not like these other stingy suppliers ha I’ll be buying from Dimes for a while bro I can tell ",
  },{
    id: 11,
    channel: "redplug vouches",
    name: "Forwarded from John",
    review: "Pleasure doing business with @DimesMgmt, smooth deal without mm ",
  },{
    id: 12,
    channel: "redplug vouches",
    name: "Forwarded from Giancarlo",
    review: "Do business with This man @DimesMgmt, fast with the accounts And polite, accounts in perfect condition And with everything recommended 100% ",
  },
  
  {
    id: 13,
    channel: "redplug vouches",
    name: "Forwarded from AS",
    review: "You are a fucking legend ",
  },{
    id: 14,
    channel: "redplug vouches",
    name: "Forwarded from Mark",
    review: "Just want to say a massive thank you to OG GC for hooking me up with some mean accounts great price easy to deal with and very helpful ",
  },{
    id: 15,
    channel: "redplug vouches",
    name: "Forwarded from Sergei",
    review: "Big vouch for my man @dimesmgmt. sold me legit reddit accs no mm ",
  },{
    id: 16,
    channel: "redplug vouches",
    name: "Forwarded from Max OFM",
    review: "hey i paid a reddit account and everything is fine they're really good ",
  },{
    id: 17,
    channel: "redplug vouches",
    name: "Forwarded from Oliver H",
    review: "vouch for @DimesMgmt good accnts ",
  }
];

export const CustomerReviews = () => {
  return (
    <div className="reviews-section">
      <div className="reviews-carousel">
        {reviews.map((review) => (
          <div className="review-card" key={review.id}>
            <h3>
              {/* Render channel with a line break after it */}
              <span className="channel">{review.channel}</span><br />
              <span>{review.name}</span>
            </h3>
            <p className="review-text">{review.review}</p>
          </div>
        ))}
      </div>
    </div>
  );
};