// src/components/Navbar.jsx
import React from "react";
import { Link } from "react-router-dom";
import { ShoppingCart, SignIn } from "phosphor-react"; // Import the SignIn icon from Phosphor
import "./navbar.css";

export const Navbar = () => {
    return (
        <div className="navbar"> 
            <div className="links"> 
            <Link to="/"> 
            <ShoppingCart size={24} />
                <Link to="/"> Shop </Link>
                
                </Link>
                <Link to="/signin" className="signin-link">
                    <SignIn size={0} /> Sign In
                </Link>
                <Link to="/contact"> Contact </Link> {/* Add Contact link */}
            </div>
        </div> 
    );
};
