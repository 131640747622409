// src/pages/aged/Aged.jsx
import React from 'react';
import character from "../../assets/character22.png"; // Import character image
import './highkarma.css'; // Ensure the CSS file is imported

const HighKarma = () => {
    return (
        <div className="high">
          <img src={character} alt="Character 2" className="aged-character" />
          <h1>High Karma Accounts Starting At $35</h1>
          
          {/* Display the character image */}
          <p className="high-description">
            2k+ Post Karma, 100+ Comment Karma | 1 Month+ Aged | Female Usernames | Includes Cookies | Created Using US Mobile Proxies
          </p>
    
          {/* Add a subtext for DM to purchase with a clickable link */}
          <p className="high-dm">
            <a href="https://t.me/dimesmgmt" target="_blank" rel="noopener noreferrer">
              dm @dimesmgmt to purchase
            </a>
          </p>
        </div>
      );
};

export default HighKarma;
