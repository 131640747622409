import productLilyana from "./assets/Lilyana.png"
import aged from "./assets/1luna.png"
import highkarma from "./assets/1bella.png"

export const PRODUCTS = [
 
  {
     id: 1,
    productName: "Aged Blanks",
    price: 70.0,
    productImage: aged,
  },

  {
    id: 2,
   productName: "High Karma Accounts",
   price: 70.0,
   productImage: highkarma,
 },

];

