import React, { useContext, useState } from "react";
import { ShopContext } from "../../context/shop-context";
import { useNavigate } from "react-router-dom"; // For navigation
import "./speech.css"; // Ensure you add CSS for the speech bubble

export const Product = (props) => {
    const { id, productName, price, productImage } = props.data;
    const { addToCart, cartItems } = useContext(ShopContext);
    const [showSpeechBubble, setShowSpeechBubble] = useState(false);
    const navigate = useNavigate();

    const cartItemAmount = cartItems[id];

    const handleAddToCart = () => {
        if (cartItemAmount > 0) {
            navigate("/aged"); // Navigate to the cart page
        } else {
            addToCart(id);
            setShowSpeechBubble(true); // Show the speech bubble
            setTimeout(() => setShowSpeechBubble(false), 2000); // Hide after 2 seconds
        }
    };

    return (
        <div className="product">
            <p className="shining-text-desc"><b>{productName}</b></p>
            <img src={productImage} />
            <div className="description">
            
            
       </div>
            <button 
    className="addToCartBttn" 
    onClick={handleAddToCart}
>
    <span className="shining-text-addtocart">
        {"See more"}
    </span>
</button>

            
        </div>
    );
};
